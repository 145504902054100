.loadercontainer {
  display: flex;
  flex-wrap: wrap;
  width: 60px;
  transform: rotate(-45deg);
  margin: auto;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  animation: scale-x 1s ease forwards;
  animation-delay: 2.3s;
}
.loadercontainer span {
  flex-basis: 50%;
  aspect-ratio: 1/1;
  display: block;
  transition: opacity 0.7s ease;
}

.tile-1 {
  background-color: #4db5ff;
  transform-origin: 100% 100%;
  animation: flip-1 0.5s linear forwards;
  border: none;
  opacity: 0;
  transition: opacity 0.7s ease;
}
.tile-2 {
  background-color: #4db5ff;
  transform-origin: 50% 100%;
  animation: flip-2 0.5s linear forwards;
  animation-delay: 0.5s;
  opacity: 0;
}
.tile-3 {
  background-color: #4db5ff;
  transform-origin: 50% 0%;
  animation: flip-4 0.5s linear forwards;
  animation-delay: 1.5s;
  opacity: 0;
}
.tile-4 {
  background-color: #4db5ff;
  transform-origin: 0% 50%;
  animation: flip-3 0.5s linear forwards;
  animation-delay: 1s;
  opacity: 0;
}

@keyframes flip-1 {
  to {
    transform: rotateY(180deg);
    opacity: 1;
  }
}
@keyframes flip-2 {
  to {
    transform: rotateX(180deg);
    opacity: 1;
  }
  .loadercontainer span {
    flex-basis: 50%;
    aspect-ratio: 1/1;
    display: block;
    transition: opacity 0.7s ease;
  }
}
@keyframes flip-3 {
  to {
    transform: rotateY(-180deg);
    opacity: 1;
  }
}
@keyframes flip-4 {
  to {
    transform: rotateX(-180deg);
    opacity: 1;
  }
}
@keyframes scale-x {
  to {
    transform: scale(50);
    opacity: 0;
  }
}

.tile-4 {
  background-color: #4db5ff;
  transform-origin: 0% 50%;
  animation: flip-3 0.5s linear forwards;
  animation-delay: 1s;
  opacity: 0;
}

@keyframes flip-1 {
  to {
    transform: rotateY(180deg);
    opacity: 1;
  }
}
@keyframes flip-2 {
  to {
    transform: rotateX(180deg);
    opacity: 1;
  }
}
@keyframes flip-3 {
  to {
    transform: rotateY(-180deg);
    opacity: 1;
  }
}
@keyframes flip-4 {
  to {
    transform: rotateX(-180deg);
    opacity: 1;
  }
}
@keyframes scale-x {
  to {
    transform: scale(50);
    opacity: 0;
  }
}
